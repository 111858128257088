import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import Image from './Image';
import { Card } from './Card';
import { media } from '../theme';
import { vr } from '../utils';

export function Departments({ departments }) {
  return (
    <div className="container">

      {/* <h2>Afdelinger</h2> */}
      <Section>
        {departments.map((p) => (
          <Card
            key={p.id}
            title={p.title}
            image={p.image}
            link={p.link}
            desc={p.desc}
            display="special"
          />
        ))}
      </Section>

    </div>
  );
}

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    width: 100%;
    margin-bottom: ${vr(1)};
  }

  @media ${media.landscapeM} {
    flex-direction: row;

    > div {
      width: 48%;
      width: calc(50% - ${vr(0.5)});
      margin-bottom: 0;
    }
  }
`;

Departments.propTypes = {
  departments: PropTypes.shape({
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};
