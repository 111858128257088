import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Image } from './Image';
import { theme, media, color } from '../theme';

export const Features = ({ gridItems }) => (
  <List>
    {gridItems.map((item) => (
      <li key={item.text}>
        <Image src={item.image} />
        <p>{item.text}</p>
      </li>
    ))}
  </List>
);

Features.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
      text: PropTypes.string,
    }),
  ).isRequired,
};

const List = styled.ul`
  display: flex;
  align-items: flex-start;
  margin-top: 3rem;

  li {
    list-style: none;
    flex-grow: 1;
    margin: 0 0.5rem;
    max-width: 11rem;

    @media ${media.tabletM} {
      margin: 0 2rem
    }

    p {
      opacity: 1;
    }

    img {
      width: 4rem;

      @media ${media.tabletM} {
        margin-bottom: .5rem
      }
    }
  }
`;
