import React from 'react';
import styled from 'styled-components';
import Color from 'color';
import { theme, media, color } from '../../theme';
import { Image } from '../Image';
import { Person as PersonComponent } from '../Person';
import { HighlightedBox } from '../HighlightedBox';

export const RegisterPerson = {

  // Internal id of the component
  id: 'person',

  // Visible label
  label: 'Person',

  // Fields the user need to fill out when adding an instance of the component
  fields: [{
    name: 'name',
    label: 'Navn',
    widget: 'string',
    default: 'Personens Navn',
  }, {
    name: 'title',
    label: 'Stilling',
    widget: 'string',
    required: false,
    hint: 'Personens stilling. Eks. Frivillig eller Formand',
  }, {
    name: 'description',
    label: 'Beskrivelse',
    widget: 'string',
    hint: 'Feltet bruges typisk til at skrive email-adresse eller tlf nr',
  }, {
    name: 'image',
    label: 'Billede',
    widget: 'image',
  }],

  // Pattern to identify a block as being an instance of this component
  // pattern: /^Person (\S+)$/,
  pattern: /<Person name=\{`(.*)`\} title=\{`(.*)`\} description=\{`(.*)`\} image=\{`(.*)`\} \/>/,

  // Function to extract data elements from the regexp match
  fromBlock(match) {
    return {
      name: match[1],
      title: match[2],
      description: match[3],
      image: match[4],
    };
  },

  // Function to create a text block from an instance of this component
  toBlock(obj) {
    return `<Person name={\`${obj.name}\`} title={\`${obj.title}\`} description={\`${obj.description}\`} image={\`${obj.image}\`} />`;
  },

  // Preview output for this component. Can either be a string or a React component
  // (component gives better render performance)
  toPreview(obj) {
    return (
      <Person name={obj.name} title={obj.title} description={obj.description} image={obj.image} isPreviewMode />
    );
  },

};

// eslint-disable-next-line
export const Person = ({ name, title, description, image, isPreviewMode }) => (
  <PersonComponent name={name || ''} title={title || ''} description={description || ''} image={image} />
);
