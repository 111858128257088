import vars from './theme.module.scss';

const variables = {};

Object.keys(vars).forEach((k) => {

  // Remove double quotes
  variables[k] = vars[k].replace(new RegExp('"', 'g'), '');

});

export const theme = variables;
