import React from 'react';
import styled from 'styled-components';
import Color from 'color';
import { theme, media, color } from '../../theme';
import { Image } from '../Image';
import { HighlightedBox } from '../HighlightedBox';

import Icon from '../../img/icon-buggy.inline.svg';

export const RegisterRentBuggy = {

  // Internal id of the component
  id: 'rentBuggy',

  // Visible label
  label: 'Lej en golfvogn',

  // Fields the user need to fill out when adding an instance of the component
  fields: [{
    name: 'link',
    label: 'Link til videre læsning',
    widget: 'string',
    default: '/gaest/lej-en-golfvogn/',
    pattern: [ '^[/]([\S])*\/?$', 'Kun delen efter .dk som starter med /' ],
    hint: 'OBS! Indsæt den del, som kommer efter sollerodgolf.dk - f.eks: /medlem/lej-en-golfvogn/',
  }],

  // Pattern to identify a block as being an instance of this component
  // pattern: /^rentBuggy (\S+)$/,
  pattern: /<RentBuggy link=\{`(.*)`\} \/>/,

  // Function to extract data elements from the regexp match
  fromBlock(match) {
    return {
      link: match[1],
    };
  },

  // Function to create a text block from an instance of this component
  toBlock(obj) {
    return `<RentBuggy link={\`${obj.link}\`} />`;
  },

  // Preview output for this component. Can either be a string or a React component
  // (component gives better render performance)
  toPreview(obj) {
    return (
      <RentBuggy isPreviewMode />
    );
  },

};

// eslint-disable-next-line
export const RentBuggy = ({ link, isPreviewMode }) => (
  <HighlightedBox
    heading="Lej en golfvogn"
    content={[ 'Kom let og hurtigt omkring.', 'Book en golfvogn forud for dit besøg.' ]}
    link={link}
    image={isPreviewMode ? <Image src={Icon} /> : <Icon />}
  />
);
