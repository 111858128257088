import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { theme } from '../theme';

const defaultStyle = {
  overlay: {
    background: theme.modalOverlayBackgroundColor,
    zIndex: 5000,
  },

  content: {
    borderRadius: 0,
    border: 0,
    boxSizing: 'border-box',
  },
};

const fullWidthContent = {
  top: '5vw',
  right: '5vw',
  bottom: '5vw',
  left: '5vw',
};

const notFullWidthContent = {
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  transform: 'translate(-50%, -50%)',
};

export function Modal({ isOpen, closeModal, children, style, overlayStyle, fullWidth }) {
  const [ displayContent, setDisplayContent ] = useState(false);
  const overlay = { ...defaultStyle.overlay, ...overlayStyle };
  const content = { ...defaultStyle.content, ...fullWidth ? fullWidthContent : notFullWidthContent, ...style };

  useEffect(() => {
    ReactModal.setAppElement('#___gatsby');
  }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      // onAfterOpen={setDisplayContent(true)}
      style={{ overlay, content }}
    >
      {children}
    </ReactModal>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  overlayStyle: PropTypes.objectOf(PropTypes.any),
  fullWidth: PropTypes.bool,
};

Modal.defaultProps = {
  fullWidth: true,
  style: {},
  overlayStyle: {},
};
