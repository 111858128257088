import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Image } from './Image';
import { Card } from './Card';
import { media, color } from '../theme';
import { vr } from '../utils';

const categoryQuery = graphql`
  query CategoryRollQuery {
    allMdx(filter: {frontmatter: {templateKey: {eq: "news-post"}, hideFromPageGeneration: {ne: true}, category: {ne: "seneste"}}}, sort: {fields: frontmatter___title, order: ASC}) {
      group(field: frontmatter___category) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            category
            title
            image {
              childImageSharp {
                resize(
                  width: 350,
                  height: 200,
                  duotone: {
                    highlight: "#cc3232",
                    shadow: "#51133d"
                  }
                ) {
                  aspectRatio
                  src
                }
              }
            }
          }
        }
        fieldValue
      }
    }
  }
`;

function CategoryPosts({ data }) {
  const { group: categories } = data.allMdx;

  return (
    <Div className="container height-auto vertical">
      <h2 className="small-pre-heading">Byguide</h2>
      {categories && categories.map(({ nodes, fieldValue: title }) => (

        <div className="country">
          <h3>{title}</h3>
          <ul>
            {nodes.map(({ frontmatter, fields }) => (
              <li>
                <Link to={fields.slug}>
                  <Image src={frontmatter.image} />
                  <h4>{frontmatter.title}</h4>
                </Link>
              </li>
            ))}
          </ul>
        </div>

      ))}
    </Div>
  );
}

const Div = styled.div`
  .country {
    width: 100%;
    margin-bottom: 100px;
  }

  ul {
    display: flex;
    width: 100%;
    justify-content: flex-start;

    li {
      list-style: none;
      width: 22%;
      display: block;
      position: relative;
      box-sizing: border-box;
      margin-right: 2%;

      h4 {
        position: absolute;
        bottom: 15px;
        left: 15px;
        right: 15px;
        line-height: 1;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        color: ${color.cream};
      }

      a:hover {
        h4 {
          color: #fff;
        }
      }
    }
  }
`;

CategoryPosts.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.shape({
        fieldValue: PropTypes.string,
        nodes: PropTypes.arrayOf(PropTypes.shape({
          fields: PropTypes.shape({
            slug: PropTypes.string.isRequired,
          }),
          frontmatter: PropTypes.shape({
            title: PropTypes.string.isRequired,
            category: PropTypes.string.isRequired,
            image: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]).isRequired,
          }),
        })),
      }),
    }),
  }).isRequired,
};

export const CategoryRoll = () => (
  <StaticQuery
    query={categoryQuery}
    render={(data, count) => <CategoryPosts data={data} count={count} />}
  />
);
