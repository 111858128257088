/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql, withPrefix } from 'gatsby';
import { theme, color } from '../theme';
import { useConfig, useImageSource } from '../utils';

export function Metadata({ origin, description, lang, meta, keywords, title, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const { url } = useConfig();

  const imageSource = useImageSource(image);

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title.length <= 43 ? `${title} – Fodboldloge.dk` : title}</title>
      <meta name="description" content={description} />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${url}/icons/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        href={`${url}/icons/favicon-32x32.png`}
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href={`${url}/icons/favicon-16x16.png`}
        sizes="16x16"
      />
      <link
        rel="mask-icon"
        href={`${url}/icons/safari-pinned-tab.svg`}
        color={color.red}
      />
      <meta name="msapplication-TileColor" content={color.red} />
      <meta name="theme-color" content={color.red} />

      <meta name="referrer" content="origin" />
      <meta name="viewport" content="width=device-width,minimum-scale=1.0,initial-scale=1.0,maximum-scale=1.0,user-scalable=no,viewport-fit=cover" />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      {/* <meta property="og:url" content="/" /> */}
      <meta property="og:image" content={imageSource} />

    </Helmet>
  );
}

Metadata.defaultProps = {
  lang: 'da',
  meta: [],
  keywords: [],
  description: '',
  image: 'img/og-image.jpg',
};

Metadata.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
};
