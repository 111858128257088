export function useImageSource(image) {
  if (image?.childImageSharp?.fluid) {
    return image.childImageSharp.fluid.src;
  }
  if (image?.childImageSharp?.resize) {
    return image.childImageSharp.resize.src;
  }
  if (image?.childImageSharp?.fixed) {
    return image.childImageSharp.fixed.src;
  }
  if (image?.url) {
    return image.url;
  }
  if (image?.publicURL) {
    return image.publicURL;
  }
  return image;
}
