import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme, media, color } from '../theme';
import { vr } from '../utils';

import checkIcon from '../img/icon-check.svg';

export function FormSuccess({ children, style }) {
  const [ display, setDisplay ] = useState(false);

  useEffect(() => {
    setDisplay(true);
  }, []);

  return (
    <Box
      style={{
        opacity: display ? 1 : 0,
        height: display ? 140 : 0,
        ...style,
      }}
    >
      <img src={checkIcon} alt="Success" />
      <span>
        {children}<br />
        Vi vender tilbage hurtigst muligt.
      </span>
    </Box>
  );
}

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 140px;
  padding: 0 ${vr(1.5)};
  background: ${color.offwhite};
  border: 1px solid ${color.green};
  width: 100%;
  box-sizing: border-box;
  transition: ${theme.transitionLongAndFunction};

  img {
    width: 50px;
    height: 50px;
    margin-right: ${vr(1)};
  }

  span {
    color: ${color.green} !important;
    font-weight: 600;
  }
`;
