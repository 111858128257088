import bp from './breakpoints.module.scss';

const mq = {};

Object.keys(bp).forEach((k) => {
  mq[k] = `(min-width: ${bp[k]})`;
});

// Mediaqueries ready for styled-components
export const media = mq;
