import React from 'react';
import styled from 'styled-components';
import Color from 'color';
import { theme, media, color } from '../../theme';
import { Image } from '../Image';
import { HighlightedBox } from '../HighlightedBox';

import Icon from '../../img/icon-gift.inline.svg';

export const RegisterMemberGetMember = {

  // Internal id of the component
  id: 'memberGetMember',

  // Visible label
  label: 'Medlem Giv Medlem',

  // Fields the user need to fill out when adding an instance of the component
  fields: [],

  // Pattern to identify a block as being an instance of this component
  // pattern: /^rentBuggy (\S+)$/,
  pattern: /<MemberGetMember \/>/,

  // Function to extract data elements from the regexp match
  fromBlock(match) {
    return {
    };
  },

  // Function to create a text block from an instance of this component
  toBlock(obj) {
    return '<MemberGetMember />';
  },

  // Preview output for this component. Can either be a string or a React component
  // (component gives better render performance)
  toPreview(obj) {
    return (
      <MemberGetMember isPreviewMode />
    );
  },

};

// eslint-disable-next-line
export const MemberGetMember = ({ isPreviewMode }) => (
  <HighlightedBox
    heading="Få en gratis greenfee billet"
    content={['Henvis en ven til klubben og få en greenfee billet hvis de melder sig ind.']}
    image={isPreviewMode ? <Image src={Icon} /> : <Icon />}
  />
);
