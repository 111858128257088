import React from 'react';
import PropTypes from 'prop-types';
import { MDXRenderer } from 'gatsby-plugin-mdx';

// export const HTMLContent = ({ children, className }) => (
//   <div dangerouslySetInnerHTML={{ __html: children }} />
// );

export const HTMLContent = ({ content, className, description }) => (
  <div className={className || 'blogpost'}>

    <div className="blogpost-intro">
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </div>

    <div dangerouslySetInnerHTML={{ __html: content }} />

  </div>
);

export const Content = ({ children, className }) => (
  <MDXRenderer className={className}>{children}</MDXRenderer>
  // <div className={className}>{children}</div>
);

export const BlogContent = ({ children, className, description }) => (
  <div className={className || 'blogpost'}>
    <div className="blogpost-intro"><p>{description}</p></div>
    <MDXRenderer>{children}</MDXRenderer>
  </div>
);

export const PreviewContent = ({ children, className }) => (
  <div className={className}>{children}</div>
);

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Content.defaultProps = {
  className: '',
  children: null,
};

HTMLContent.propTypes = Content.propTypes;
