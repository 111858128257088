import { graphql, useStaticQuery } from 'gatsby';

export const useConfig = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
            startYear
            startMonth
          }
        }
      }
    `,
  );

  const url = process.env.NODE_ENV === 'production' ? 'https://fodboldloge.dk' : 'http://localhost:8000';

  return {
    ...site.siteMetadata,
    url,
  };
};
