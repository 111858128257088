import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { CookiesProvider } from 'react-cookie';
import { Header } from './Header';
import { Footer } from './Footer';
import { CookieConsent } from './CookieConsent';
import { theme, media, color } from '../theme';
import { useDynamicOverflow, vr } from '../utils';

import { RentBuggy, ContactInfo, SplitView, MemberGetMember, Person } from './editorComponents';

export function Layout({ children, hideFooterContactForm }) {
  // const rootPath = `${__PATH_PREFIX__}/`
  // const blogPath = `${__PATH_PREFIX__}/nyheder/`

  useDynamicOverflow(theme.headerBackgroundColor, theme.footerBackgroundColorBottom);

  // const [ cookies, setCookie ] = useCookies(['name']);

  // function onChange(newName) {
  //   setCookie('name', newName, { path: '/' });
  // }

  const shortcodes = { RentBuggy, ContactInfo, SplitView, MemberGetMember, Person };

  return (
    <CookiesProvider>
      <Wrapper>

        <a href="#c" id="skipToContent" tabIndex="0">Gå til indhold »</a>

        <Header />

        <MDXProvider components={shortcodes}>
          <div className="page" id="c">{children}</div>
        </MDXProvider>
        <Footer hideContactForm={hideFooterContactForm} />

        <CookieConsent />
      </Wrapper>
    </CookiesProvider>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
`;
