import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Color from 'color';
import _ from 'lodash';
import { vr } from '../utils';
import { theme, media, color } from '../theme';

export function ElevatedInfo({ children, offset, style, direction, height, marginTop }) {
  const hasImage = typeof (_.find(children, (o) => o.props.className === 'image')) !== 'undefined';

  return (
    <Box marginTop={marginTop}>
      <div className="container">
        <section
          data-height={height}
          data-direction={direction}
          data-image={hasImage}
          style={{ transform: `translateY(${offset}px)`, opacity: offset ? 0 : 1, ...style }}
        >
          {children}
        </section>
      </div>
    </Box>
  );
}

ElevatedInfo.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  direction: PropTypes.string,
  height: PropTypes.string,
  marginTop: PropTypes.number,
  offset: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.any),
};

ElevatedInfo.defaultProps = {
  direction: 'row',
  height: 'auto',
  marginTop: -140,
  offset: 0,
  style: {},
};

const Box = styled.div`
  > .container > section {
    height: auto;
    max-height: initial;
    min-height: initial;
    background: #fff;
    max-width: 1000px;
    margin: ${(props) => props.marginTop / 2}px auto 0;
    position: relative;
    z-index: 200;
    display: flex;
    padding: 0 0 ${vr(1)} 0;
    box-shadow: 0 15px 30px 0 ${Color(color.brown).alpha(0.2).string()};
    transition-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
    transition-duration: .8s, .6s;
    transition-property: transform, opacity;
    transition-delay: 0s;

    &[data-direction="column"] {
      flex-direction: column;
    }

    @media ${media.landscapeM} {
      padding: ${vr(2.25)} ${vr(1.5)};
      box-sizing: border-box;
      margin-top: ${(props) => props.marginTop}px;
      
      &[data-height="fixed"] {
        max-height: 380px;
        height: 50vw;
        padding: 0;
        box-sizing: content-box;
      }

      &[data-image="true"] .content {
        width: 65%;
      }
    }

    &[data-height="fixed"] .content {
      @media ${media.desktopS} {
        padding: 0 4rem;
      }
    }

    .content {
      box-sizing: border-box;
      padding: 2rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media ${media.landscapeM} {
        padding: 0 2rem;
      }

      p {
        font-size: 0.95rem;
      }

      h1 {
        margin-top: 0;
        font-size: 1rem;

        @media ${media.landscapeM} {
          font-size: 1.6rem;
        }

        @media ${media.tabletL} {
          font-size: 1.8rem;
        }

        @media ${media.desktopS} {
          font-size: 2rem;
        }
      }
    }

    .image {
      display: none;
      overflow: hidden;

      > img {
        position: absolute;
      }

      &[data-position="left center"] {
        /* Netlify Preview */
        > img {
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        /* childImageSharp */
        picture img {
          object-position: left center !important;
        }
      }
      
      &[data-position="right center"] {
        /* Netlify Preview */
        > img {
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        /* childImageSharp */
        picture img {
          object-position: right center !important;
        }
      }

      &[data-position="center top"] {
        /* Netlify Preview */
        > img {
          left: 50%;
          top: 0;
          transform: translateX(-50%);
        }

        /* childImageSharp */
        picture img {
          object-position: center top !important;
        }
      }

      &[data-position="center bottom"] picture img {
        object-position: center bottom !important;
      }

      @media ${media.landscapeM} {
        display: block;
        width: 35%;
      }
    }
  }
`;
