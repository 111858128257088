import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Color from 'color';
import { Nav } from './Nav';
import { Hamburger } from './Hamburger';
import { media, theme, color, breakpoints } from '../theme';
import { useConfig, useWindowWidth, useSize, vr, useDisableScroll } from '../utils';

/* IMAGE */
import Logo from '../img/logo.inline.svg';
import background from '../img/nav.svg';
import burst from '../img/burst.svg';
import Facebook from '../img/social/facebook.inline.svg';
import Instagram from '../img/social/instagram.inline.svg';

export function Header() {
  const { title } = useConfig();

  const navPrimary = [
    { name: 'Forside', href: '/' },
    { name: 'Rejser', href: '/fodboldrejser' },
    { name: 'Fodboldloge', href: '/om-os' },
    { name: 'Kontakt', href: '/kontakt' },
  ];

  // Initial window width
  const windowWidth = useWindowWidth();

  // If mobile nav is expanded
  const [ isExpanded, setIsExpanded ] = useState(false);

  // If user is on desktop
  const [ isDesktop, setIsDesktop ] = useState(windowWidth >= breakpoints.tabletM);

  // Handles Resize
  useEffect(() => {
    setIsDesktop(windowWidth >= breakpoints.tabletM);
  }, [windowWidth]);

  // Set scroll position when expanding
  useEffect(() => {
    if (!isDesktop) {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });

      useDisableScroll(isExpanded);
      console.log(isExpanded);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (isDesktop) {

      // Nav items are always visible on desktop
      setIsExpanded(true);

      // Scroll should never be blocked on desktop
      useDisableScroll(false);

    } else {

      // Nav items are hidden by default on mobile
      setIsExpanded(false);

    }
    // else setIsExpanded(true); // forkertetttt
  }, [isDesktop]);

  return (
    <Head>
      <nav className="nav-container">
        <Link to="/" title="Fodboldloge" className="logo">
          <Logo />
        </Link>
        {/* <Nav /> */}
        <ul
          role="navigation"
          aria-label="main-navigation"
          aria-hidden={!isExpanded}
          className="nav-primary"
        >
          {navPrimary.map(({ href, name }) => (
            <li key={name}>
              <Link to={href}>{name}</Link>
            </li>
          ))}
        </ul>
        <ul className="nav-social" aria-hidden={!isExpanded}>
          <li><a target="_BLANK" rel="noopener noreferrer" href="https://www.instagram.com/fodboldloge.dk/" title="Fodboldloge's Instagram"><Instagram /></a></li>
          <li><a target="_BLANK" rel="noopener noreferrer" href="https://www.facebook.com/fodboldloge/" title="Fodboldloge's Facebook"><Facebook /></a></li>
        </ul>
      </nav>
      <Hamburger
        ariaExpanded={isExpanded}
        ariaControls="mainNav"
        ariaLabel="Primær menu"
        id="toggleNav"
        onClick={() => setIsExpanded(!isExpanded)}
      />
    </Head>
  );
}

const headerLogoContainerHeight = useSize(theme.headerLogoContainerHeight);
const headerLogoContainerWidth = useSize(theme.headerLogoContainerWidth);
const headerScale = useSize(theme.headerScale);
const headerScaleTablet = useSize(theme.headerScaleTablet);
const headerLogoSize = useSize(theme.headerLogoSize);

const Head = styled.header`
  position: absolute;
  top: -1px;
  right: 0;
  left: 0;
  z-index: 999;
  pointer-events: none;
  background: transparent url(${background}) no-repeat;
  filter: drop-shadow( 2px 2px 5px rgba(0, 0, 0, .1));

  /* &[aria-expanded="true"] {
    position: fixed;
  } */

  .nav-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    height: 100px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    position: relative;
    z-index: 1000;

    svg {
      width: ${headerLogoSize * 10}px;
      height: ${headerLogoSize * 10}px;
      color: ${color.darkteal};
    }

    &:hover svg {
      color: ${color.black};
    }

    @media ${media.tabletM} {
      height: ${headerLogoContainerHeight * (headerScaleTablet / 100)}vw;
      width: ${headerLogoContainerWidth * (headerScaleTablet / 100)}vw; /* circa i midten mellem start hjørne (10.8) og slut hjørne (13.3vw) */

      svg {
        width: ${headerLogoSize * (headerScaleTablet / 100)}vw;
        height: ${headerLogoSize * (headerScaleTablet / 100)}vw;
      }
    }

    @media ${media.desktopS} {
      height: ${theme.headerLogoContainerHeight};
      width: ${theme.headerLogoContainerWidth};

      svg {
        width: ${theme.headerLogoSize};
        height: ${theme.headerLogoSize};
      }
    }
  }

  .nav-primary,
  .nav-social {
    height: 50px;
    padding: 0;
    display: flex;
    align-items: stretch;
    pointer-events: auto;
    align-self: flex-start;
    transform: translateX(-110%);
    transition: transform .2s;

    &[aria-hidden="false"] {
      transform: translateX(0%);
    }

    @media ${media.tabletM} {
      display: flex;
      height: ${headerScaleTablet * 0.05}vw;
      transform: translateX(0);
      transition: none;
    }

    @media ${media.desktopS} {
      height: ${headerScale * 0.05}vw;
    }
  }

  li {
    list-style: none;

    a {
      display: inline-block;
      height: 100%;
      text-decoration: none;

      &:hover {
        color: ${color.black};
      }
    }
  }

  .nav-primary {
    margin: 0 0 0 0.4em;
    position: absolute;
    top: -5vw;
    left: -5vw;
    height: 110vh;
    width: 110vw;
    background-color: #fff;
    flex-direction: column;
    padding: 25vh 20vw;
    display: flex;

    @media ${media.tabletM} {
      position: static;
      top: 0;
      left: 0;
      height: inherit;
      width: auto;
      flex-grow: 1;
      background-color: transparent;
      padding: 0;
      flex-direction: row;
    }
    
    li {
      display: flex;
      align-items: center;

      a {
        font-size: 2rem;
        line-height: 2;
        padding: 0 2vw;
        font-family: 'Barlow Condensed', sans-serif;
        letter-spacing: .15em;
        font-weight: 400;
        text-transform: uppercase;
        color: ${color.lightteal};
        transition: color .2s ease-in-out;

        @media ${media.tabletM} {
          line-height: ${5 * (headerScaleTablet / 100)}vw;
          font-size: 1rem;
        }

        @media ${media.desktopS} {
          line-height: 5vw;
        }
      }

      &:before {
        display: none;
        width: .3rem;
        height: .3rem;
        background: url(${burst}) 0 0 no-repeat;
        background-size: 100% auto;
        content: "";

        @media ${media.tabletM} {
          display: inline-block;
        }
      }

      &:first-child:before {
        @media ${media.tabletM} {
          display: none
        }
      }
    }

    @media ${media.tabletM} {
      margin-left: -0.4em;
    }
  }

  .nav-social {
    display: flex;
    margin: 0;
    position: absolute;
    top: 85vh;
    left: 0;
    right: 0;
    padding-right: 10vw;
    box-sizing: border-box;
    justify-content: flex-end;

    li a {
      display: flex;
      align-items: center;
      padding: 0 .7em;

      &:hover svg * {
        fill: ${color.black};
      }
      
      svg {
        width: 1.7em;
        height: 1.7em;

        * {
          fill: ${color.teal};
        }
      }
    }

    @media ${media.tabletM} {
      display: flex;
      position: relative;
      top: 0;
      right: 0;
      padding-right: 1.5vw;

      li a {
        /* padding: 0; */

        svg {
          width: 1.1em;
          height: 1.1em;
        }
      }
    }

    &[aria-hidden="false"] {
      transition-delay: .1s;
    }
  }

  &, &:before, &:after {
    background-size: auto ${headerScale * 2}px;
    background-position: ${0 - headerScale - 5}px bottom;
    height: ${headerScale}px;

    @media ${media.tabletM} {
      height: auto;
      position: fixed;
      background-size: ${theme.headerScaleTablet} auto;
      background-position: left bottom;
      padding-bottom: calc(100% * ${headerScaleTablet} / 1020);
    }

    @media ${media.desktopS} {
      background-size: ${theme.headerScale} auto;
      padding-bottom: calc(100% * ${headerScale} / 1020);
    }
  }

  &:before,
  &:after {
    content: "";
    background: url(${background}) no-repeat;
    top: 0;
    width: 6px;
  }

  &:before {
    display: block;
    left: -3px;
    transform: scaleX(-1);
    background-position: -5px 0;
  }
    
  &:after {
    right: -3px;
    background-position: -95vw 0;
    display: none;

    @media ${media.tabletM} {
      display: block;
    }
  }
`;
