import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Color from 'color';
import PropTypes from 'prop-types';
import { theme, media, color } from '../theme';
import { Image } from './Image';
import { isLocalLink } from '../utils';

export function Card({ image, link, title, date, desc, display }) {
  const content = (
    <div className="content">
      {date && <small>{date}</small>}
      {title && <h3 data-font="condensed" style={{ color: color.white }}>{title}</h3>}
      {desc && <p>{desc}</p>}
    </div>
  );

  const overlay = display !== 'special';

  return (
    <CardComponent data-display={display}>
      <Image src={image} />
      {isLocalLink(link)
        ? <Link to={link} data-overlay={overlay}>{content}</Link>
        : <a href={link} data-overlay={overlay}>{content}</a>
      }
    </CardComponent>
  );
}

Card.propTypes = {
  image: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]).isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  desc: PropTypes.string,
  display: PropTypes.string,
};

Card.defaultProps = {
  date: null,
  desc: null,
  display: 'normal',
};

const CardComponent = styled.div`
  position: relative;
  overflow: hidden;
  box-shadow: 0 15px 30px 0 ${Color(color.brown).alpha(0.2).string()};

  &:before {
    content: "";
    display: block;
    padding-top: 50%;
    width: 100%;
    overflow: hidden;
  }

  .gatsby-image-wrapper {
    top: 0;
    left: 0;
    position: absolute;
  }

  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &:focus {
      outline: ${theme.linkOutline};
    }
  }

  .content {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    z-index: 15;
  }

  small {
    color: ${color.cream};
    opacity: .8;
  }

  h3 {
    margin: 0;
    color: ${color.white};
    line-height: 1;
    /* font-size: 1.6rem; */
  }

  &[data-display="special"] {
    transition: ${theme.transitionShortAndFunction};
    box-shadow: none;

    &:before {
      padding-top: 35%;
    }

    .content {
      top: 1rem;
      left: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    img {
      position: absolute;
      top: 50%;
      right: ${theme.containerPadding};
      transform: translate(0, -50%);
      /* transform: translate(15%, 20%); */
      width: 100px;
    }

    h3 {
      color: ${color.brown};
      font-size: 1.6rem;
    }

    p {
      width: 70%;
    }

    small {

    }

    &:hover {
      background: ${color.white};
      box-shadow: 0 15px 30px 0 ${Color(color.brown).alpha(0.1).string()};
    }
  }
`;
