import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { theme, media, color } from '../theme';
import { useSize } from '../utils';

export function Hamburger({ ariaExpanded, ariaControls, ariaLabel, id, onClick }) {

  return (
    <Button
      data-target="navMenu"
      type="button"
      aria-expanded={ariaExpanded}
      aria-controls={ariaControls}
      id={id}
      aria-label={ariaLabel}
      className="navbar-burger burger"
      onClick={() => onClick()}
    >
      <span />
      <span />
      <span />
    </Button>
  );

}

Hamburger.propTypes = {
  ariaControls: PropTypes.string.isRequired,
  ariaExpanded: PropTypes.bool.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string,
};

Hamburger.defaultProps = {
  id: 'hamburger',
};

const negativeMargin = `${(useSize(theme.navHamburgerButtonSize) - useSize(theme.navHamburgerBeefWidth)) / -2}px`;

const Button = styled.button`
  display: block;
  padding: 2.5vw 0;
  width: 10vw;
  position: absolute;
  top: 50px;
  right: 5vw;
  z-index: 99999;
  cursor: pointer;
  pointer-events: auto;
  appearance: none;
  border: 0;
  background: transparent;
  transform: translateY(-50%);

  @media ${media.tabletM} {
    display: none
  }

  span {
    display: block;
    width: 80%;
    height: 4px;
    background: ${color.white};
    margin: 0 auto 9px;
    position: relative;
    transition: all .2s;
    border-radius: 2px;

    &:nth-child(3) {
      margin-bottom: 0;
    }
  }

  &[aria-expanded="true"] {
    outline: 0 !important;

    span {
      background: ${color.teal};

      &:nth-child(1) {
        transform: translateY(13px) rotate(45deg);
      }

      &:nth-child(3) {
        transform: translateY(-13px) rotate(-45deg);
      }

      &:nth-child(2) {
        transform: scale(0);
      }
    }
  }

  /* &:focus {
    background: ${color.gold};
    
    > span {
      background: ${color.offwhite};
    }
  } */

  /* @media ${media.displayNav} {
    display: none;
  }

  span {
    display: block;
    width: ${theme.navHamburgerBeefWidth};
    height: ${theme.navHamburgerBeefHeight};
    background: ${theme.navHamburgerBeefColor};
    margin: 0 auto ${theme.navHamburgerBeefMargin};
    position: relative;
    transition: all ${theme.transitionShort};
    border-radius: ${theme.navHamburgerBeefBorderRadius};

    &:nth-child(2) {
      width: ${useSize(theme.navHamburgerBeefWidth) + 2}px;
    }

    &:nth-child(3) {
      margin-bottom: 0;
    }
  }

  &[aria-expanded="true"] {

    span {
      background: ${theme.navHamburgerBeefActive};

      &:nth-child(1) {
        transform: translateY(${useSize(theme.navHamburgerBeefHeight) + useSize(theme.navHamburgerBeefMargin)}px) rotate(45deg);
      }

      &:nth-child(3) {
        transform: translateY(-${useSize(theme.navHamburgerBeefHeight) + useSize(theme.navHamburgerBeefMargin)}px) rotate(-45deg);
      }

      &:nth-child(2) {
        transform: scale(0);
      }
    }
  } */
`;
