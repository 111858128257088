import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Color from 'color';
import PropTypes, { array } from 'prop-types';
import { theme, media, color } from '../theme';

const container = {
  width: '100%',
  boxSizing: 'border-box',
  maxWidth: 650,
  boxShadow: theme.containerShadow,
  background: color.white,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  border: '4px solid transparent',
  padding: '2rem',
};

const leftside = {
  width: '60%',
};

const h2 = {
  marginTop: 0,
};

const img = {
  width: '30%',
  marginBottom: -20,
};

export const HighlightedBox = ({ heading, content, link, linkText, image }) => (
  <SC>
    <div data-margin="small" className="component" style={container}>
      <div className="content" style={leftside}>

        <h2 style={h2}>{heading}</h2>

        { Array.isArray(content)
          ? content.map((p) => <p>{p}</p>)
          : <p>{content}</p>
        }

        { link && <Link className="fancy" to={link}>{linkText}</Link> }

      </div>
      <div className="image" style={img}>
        {image}
      </div>
    </div>
  </SC>
);

HighlightedBox.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.oneOfType([ PropTypes.string, PropTypes.arrayOf(PropTypes.string) ]).isRequired,
  link: PropTypes.string,
  linkText: PropTypes.string,
  image: PropTypes.objectOf(PropTypes.object).isRequired,
};

HighlightedBox.defaultProps = {
  heading: null,
  link: null,
  linkText: 'Læs mere',
};

const SC = styled.div`
  color: ${color.green};

  h2, p {
    color: currentColor;
  }

  svg path {
    fill: currentColor;
  }

  p + p {
    margin-top: 0;
  }

  a {
    color: currentColor;
    cursor: pointer;
    /* text-decoration: none; */

    &:hover {
      color: ${color.gold};
      text-decoration: underline;
    }
  }

  .component{
    transition: ${theme.transitionShortAndFunction};

    &:hover {
      box-shadow: 0 30px 45px rgba(0,0,0,.12) !important;
      transform: translateY(-5px);
    } 
  }
`;
