import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Image } from './Image';
import { media, theme } from '../theme';
import { vr, stringToKey } from '../utils';


const ImageLink = ({ link, title, image, isPreviewMode }) => (
  <a href={link && !isPreviewMode ? link : '/'} title={title || ''} rel="nofollow">
    <Image src={image} alt={title} />
  </a>
);

const IconText = ({ title, image, desc }) => (
  <>
    {image && <Image src={image} alt={title} />}
    <strong>{title}</strong>
    <p>{desc}</p>
  </>
);

export const IconRow = ({ style, fields, align, display, margin, isPreviewMode }) => (
  <div>
    <div className="container" style={{ marginTop: vr(-1) }}>
      <List style={style} data-count={fields.length} data-align={align} data-display={display} data-margin={margin}>
        {fields.map(({ title, link, image, desc }) => (
          <li key={stringToKey(title || link, 'IconRow')}>
            { display === 'bigLinkImage'
              ? <ImageLink link={link} title={title} image={image} isPreviewMode={isPreviewMode} />
              : <IconText title={title} desc={desc} image={image} />
            }
          </li>
        ))}
      </List>
    </div>
  </div>
);

IconRow.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  fields: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    desc: PropTypes.string,
    image: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
  })),
  align: PropTypes.string,
  display: PropTypes.string,
  margin: PropTypes.string,
  isPreviewMode: PropTypes.bool,
};

IconRow.defaultProps = {
  style: {},
  fields: [],
  align: 'center',
  display: 'normal',
  margin: 'medium',
  isPreviewMode: false,
};

const List = styled.ul`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;

  li {
    width: 50%;
    list-style: none;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-direction: column;
    margin: ${vr(1)} 0 0;
    padding: 0;

    p {
      margin: 0 ${theme.containerPadding};

      @media ${media.tabletS} {
        width: 100%;
        margin: 0 auto;
        max-width: 200px;
      }

      @media ${media.desktopS} {
        width: 60%;
      }
    }

    .gatsby-image-wrapper, img {
      margin: 0 auto
    }

    strong {
      margin-top: ${vr(0.5)};
    }

    img {
      height: 64px;
      width: 64px;
    }
  }

  &[data-align="left"] {
    li {
      text-align: left;
      align-content: flex-start;

      .gatsby-image-wrapper, p {
        margin-left: 0;
      }
    }
  }

  @media ${media.landscapeM} {
    justify-content: space-between;

    &[data-count="3"] li,
    &[data-count="6"] li,
    &[data-count="9"] li {
      width: 33%;
    }
  }

  @media ${media.tabletM} {
    &[data-count="4"],
    &[data-count="7"],
    &[data-count="8"],
    &[data-count="12"] {
      
      li {
        width: 25%;
        /* width: auto;  */

        p {
          max-width: 200px;
          width: 100%;
        }
      }
    }

    &[data-count="5"],
    &[data-count="10"] {
      justify-content: space-between;
      
      li {
        /* width: 20%; */
        width: auto; 

        p {
          width: 180px;
        }
      }
    }
  }

  &[data-display="bigLinkImage"] {
    justify-content: space-between;

    li {
      flex-grow: 1;
      width: auto;

      .gatsby-image-wrapper {
        filter: brightness(40%) sepia(100%);
        opacity: .5;
        transition: ${theme.transitionShort} ${theme.transitionFunction};
      }

      a:hover .gatsby-image-wrapper {
        opacity: .8;
      }
    }
  }
`;
