import { useState, useEffect } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

export function useDynamicOverflow(top, bottom, threshold = 350, applyOnInitialRender = true) {
  const [ currentBackgroundColor, setCurrentBackgroundColor ] = useState(top);

  /**
   * Change state and html background color
   * @param {string} color
   */
  const changeColor = (color) => {
    setCurrentBackgroundColor(color);
    document.body.style.backgroundColor = color;
  };

  /**
   * Applies top color as background once without having to scroll
   */
  useEffect(() => {
    if (applyOnInitialRender) {
      changeColor(top);
    }
  }, []);

  /**
   * Checks if background color
   * should be changed based on position
   */
  useScrollPosition(({ currPos }) => {
    if (currPos.y >= -threshold && currentBackgroundColor !== top) {
      changeColor(top);
    } else if (currPos.y < -threshold && currentBackgroundColor !== bottom) {
      changeColor(bottom);
    }
  });
}
