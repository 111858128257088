import React from 'react';
import styled from 'styled-components';
import Color from 'color';
import Markdown from 'markdown-to-jsx';
import { File } from './File';
import { theme, media, color } from '../../theme';
import { vr } from '../../utils';

const css = `
  .split-view {
    display: flex;
    align-items: center;
  }

  .split-view .sw-table {
    width: 55%;
  }

  .split-view .sw-table table {
    width: 100%;
  }

  .split-view .sw-text {
    width: 45%;
  }

  .split-view .sw-text *:first-child {
    margin-top: 0;
  }

  .split-view.table-left .sw-table {
    order: 0;
  }

  .split-view.table-left .sw-text {
    padding-left: ${vr(3)};
  }

  .split-view.table-right .sw-table {
    order: 2;
  }

  .split-view.table-right .sw-text {
    padding-right: ${vr(3)};
  }
`;

export const RegisterSplitView = {

  // Internal id of the component
  id: 'splitView',

  // Visible label
  label: 'Split indhold',

  // Fields the user need to fill out when adding an instance of the component
  fields: [
    {
      name: 'align',
      label: 'Juster',
      widget: 'select',
      default: 'table-right',
      options: [{ label: 'Tabel til venstre', value: 'table-left' }, { label: 'Tabel til højre', value: 'table-right' }],
    }, {
      name: 'table',
      label: 'Tabel',
      widget: 'table',
    }, {
      name: 'text',
      label: 'Tekst',
      widget: 'markdown',
      buttons: [ 'bold', 'italic', 'link', 'heading-two', 'heading-three', 'heading-four', 'heading-five', 'heading-six', 'quote', 'bulleted-list', 'numbered-list' ],
      editorComponents: ['file'],
      hint: 'Feltet er ikke egnet til en lang smørre af tekst.',
    }],

  // Pattern to identify a block as being an instance of this component
  // pattern: /^rentBuggy (\S+)$/,
  // pattern: /<SplitView data-align={`([a-zA-Z_\- ]*)`}><div>([\s\S]*)<\/div><div>\n([\s\S]*)\n<\/div><\/SplitView>/,
  pattern: /<SplitView data-align={`([a-zA-Z_\- ]*)`}>[\s]*<div>[\s]*([\s\S]*?)[\s]*<\/div>[\s]*<div>([\s\S]*?)<\/div>[\s]*<\/SplitView>/,

  // Function to extract data elements from the regexp match
  fromBlock(match) {
    return {
      align: match[1],
      table: match[2],
      text: match[3],
    };
  },

  // Line break before ${obj.text} is essential!! Don't remove
  toBlock(obj) {
    return `

<SplitView data-align={\`${obj.align}\`}>
<div>${obj.table}</div>
<div>

${obj.text}

</div>
</SplitView>

`;
  },

  // Preview output for this component. Can either be a string or a React component
  // (component gives better render performance)
  toPreview(obj) {
    return (
      <SplitView isPreviewMode data-align={obj.align}>
        <div>{obj.table}</div>
        <div>{obj.text}</div>
      </SplitView>
    );
  },

};

// eslint-disable-next-line
export const SplitView = (props) => {
  const { isPreviewMode } = props;
  const align = props['data-align'] || 'table-right';
  const [ table, text ] = props.children;

  // const isTableLeft = align === 'table-left';

  // const split = {
  //   display: 'flex',
  //   alignItems: 'center',
  // };

  // const tableView = {
  //   width: '55%',
  //   order: isTableLeft ? 0 : 2,
  // };

  // const textView = {
  //   width: '45%',
  //   paddingLeft: isTableLeft ? vr(3) : 0,
  //   paddingRight: isTableLeft ? 0 : vr(3),
  // };

  return (
    <div className={`split-view ${align}`} data-margin="large">
      <style>{css}</style>
      { isPreviewMode ? (
        <>
          <div className="sw-table" dangerouslySetInnerHTML={{ __html: table.props.children }} />
          <div className="sw-text"><Markdown>{text.props.children}</Markdown></div>
        </>
      ) : (
        <>
          <div className="sw-table">{table}</div>
          <div className="sw-text">{text}</div>
        </>
      )}
    </div>
  );
};
