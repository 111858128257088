import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link, graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import Color from 'color';
import { theme, breakpoints, media, color } from '../theme';
import { Hamburger } from './Hamburger';

import { capitalize, useWindowWidth, useSize, vr, useDisableScroll } from '../utils';

const navItemQuery = graphql`
  query QueryItem{
    allFile(filter: {extension: {eq: "md"}, relativeDirectory: {nin: ""}}) {
      group(field: relativeDirectory) {
        nodes {
          name
          childMdx {
            frontmatter {
              title
            }
            fields {
              slug
            }
          }
          relativeDirectory
        }
        fieldValue
      }
    }
  }
`;

const HideNavOnFocus = (props) => (
  <span
    tabIndex="0"
    style={{
      opacity: 0,
      position: 'absolute',
      width: 0,
      height: 0,
      outline: 0,
    }}
    onFocus={() => props.onFocus()}
  />
);

HideNavOnFocus.propTypes = { onFocus: PropTypes.func.isRequired };

function Navbar({ data }) {
  // Static items to be prepended to list
  const staticListItems = [
    { fieldValue: 'nyheder', href: '/nyheder', nodes: [] },
  ];

  // Order of nav items
  const order = {
    nyheder: 0,
    baner: 1,
    gaest: 2,
    klub: 3,
    medlem: 4,
    klubber: 5,
  };

  // NavItems from Query
  const navItemsList = [ ...staticListItems, ...data.allFile.group ].sort(({ fieldValue: a }, { fieldValue: b }) => ((order[a] > order[b]) ? 1 : -1));

  //
  const initialNavItems = navItemsList.reduce((accumulator, current) => ({ ...accumulator,
    [current.fieldValue]: {
      //
      expanded: false,

      //
      ref: useRef(null),

      //
      previous: Object.keys(accumulator),
    } }), {});

  // Make object of all slugs with refs
  const navItemsRef = navItemsList.reduce((accumulator, current) => ({ ...accumulator, [current.fieldValue]: useRef(null) }), {});

  // Size to push down siblings
  const [ siblingsOffset, setSiblingsOffset ] = useState(0);

  // List of navItems not to move (translateY)
  const [ siblingsBeforeActiveNavItem, setSiblingsBeforeActiveNavItem ] = useState([]);

  // Initial window width
  const windowWidth = useWindowWidth();

  // If mobile nav is expanded
  const [ isExpanded, setIsExpanded ] = useState(false);

  // Object of each items expanded state, for mobile and desktop mouseover
  //
  const [ navItems, setNavItems ] = useState(initialNavItems);

  // If user is on desktop
  const [ isDesktop, setIsDesktop ] = useState(false);

  function showNavItemHideRest(slug) {
    const submenuHeight = navItems[slug].ref.current.offsetHeight;
    setSiblingsOffset(submenuHeight);

    setNavItems({
      ...initialNavItems,
      [slug]: {
        ...navItems[slug],
        expanded: true,
      },
    });

    setSiblingsBeforeActiveNavItem(navItems[slug].previous);
  }

  function hideAllNavItems() {
    setSiblingsOffset(0);

    setNavItems(initialNavItems);
  }

  function toggleNavItem(slug) {
    if (navItems[slug].expanded) {
      hideAllNavItems();
    } else {
      showNavItemHideRest(slug);
    }
  }

  function handleNavItemFocus(slug = false) {
    // if (isDesktop && slug) showNavItemHideRest(slug);

    if (isDesktop && !slug) hideAllNavItems();
  }

  function handleNavItemHover(slug) {
    if (isDesktop) showNavItemHideRest(slug);
  }

  function handleNavItemClick(slug) {
    toggleNavItem(slug);
  }

  function handleNavDropdownMouseLeave() {
    if (isDesktop) hideAllNavItems();
  }

  function sortNavChildItems(a, b) {
    if (a === 'bridge') return 1;
    if (b === 'bridge') return -1;
    return a > b ? 1 : -1;
  }

  // Convert navItem name
  function beautifyName(name) {
    switch (name) {
    case 'gaest':
      return 'Gæst';
    case 'klubber':
      return 'Klubber i Klubben';
    default:
      return capitalize(name);
    }
  }

  // Handles Resize
  useEffect(() => {
    setIsDesktop(windowWidth >= breakpoints.displayNav);
  }, [windowWidth]);

  // Set scroll position when expanding
  useEffect(() => {
    if (!isDesktop) {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });

      useDisableScroll(isExpanded);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (isDesktop) {

      // Nav items are always visible on desktop
      setIsExpanded(true);

      // Scroll should never be blocked on desktop
      useDisableScroll(false);

    } else {

      // Nav items are hidden by default on mobile
      setIsExpanded(false);

    }
    // else setIsExpanded(true); // forkertetttt
  }, [isDesktop]);

  const ListItemLink = ({ type, to, tabIndex, children }) => (type === 'file'
    ? <a href={to} tabIndex={tabIndex}>{children}</a>
    : <Link to={to} tabIndex={tabIndex}>{children}</Link>
  );

  return (
    <nav role="navigation" className="nav-primary">
      <Hamburger
        ariaExpanded={isExpanded}
        ariaControls="mainNav"
        ariaLabel="Primær menu"
        id="toggleNav"
        onClick={() => setIsExpanded(!isExpanded)}
      />

      <HideNavOnFocus onFocus={() => hideAllNavItems()} />

      <div id="mainNavContainer" aria-hidden={!isExpanded} data-animation="slideInLeft">
        <ul id="mainNav" data-font="body">
          {navItemsList.map((item) => {
            const shouldBeOffset = !isDesktop && !(navItems[item.fieldValue].expanded || siblingsBeforeActiveNavItem.includes(item.fieldValue));

            return (
              <li
                key={item.fieldValue}
                className={item.fieldValue}
                onMouseLeave={() => handleNavDropdownMouseLeave()}
                data-active={navItems[item.fieldValue].expanded}
                style={{ transform: `translateY(${shouldBeOffset ? siblingsOffset : 0}px)` }}
              >

                {item.nodes.length > 0
                  ? (
                    <button
                      type="button"
                      aria-expanded={navItems[item.fieldValue].expanded}
                      aria-haspopup="true"
                      onFocus={() => handleNavItemFocus(item.fieldValue)}
                      onClick={() => handleNavItemClick(item.fieldValue)}
                      onMouseOver={() => handleNavItemHover(item.fieldValue)}
                    >
                      {beautifyName(item.fieldValue)}
                    </button>
                  )
                  : <Link onFocus={() => handleNavItemFocus()} to={item.href}>{beautifyName(item.fieldValue)}</Link>}

                {item.nodes.length > 99
                  && (
                    <ul
                      aria-hidden={!navItems[item.fieldValue].expanded}
                      ref={navItems[item.fieldValue].ref}
                    >
                      {item.nodes.sort(({ name: a }, { name: b }) => sortNavChildItems(a, b)).map(({ childMdx, name }) => {
                        const subItem = childMdx.frontmatter;

                        const type = subItem.nav?.file?.relativePath ? 'file' : 'link';
                        const url = type === 'file' ? `/img/${subItem.nav.file.relativePath}` : childMdx.fields.slug;
                        const title = subItem.nav.title || subItem.title;
                        const description = subItem.nav.description.substr(0, 40);

                        return (
                          <li key={name}>
                            <ListItemLink type={type} to={url} tabIndex={navItems[item.fieldValue].expanded ? 0 : -1}>
                              <strong>{title}</strong>
                              <span>{description}</span>
                            </ListItemLink>
                          </li>
                        );
                      })}
                    </ul>
                  )}
              </li>
            );
          })}
          <li
            aria-hidden="true"
            className="theend"
            style={{ transform: `translateY(${!isDesktop ? siblingsOffset : 0}px)` }}
          />
        </ul>
      </div>

      <Link to="/bliv-medlem" id="navMemberSignup" className="btn gold-fill height-auto" onFocus={() => hideAllNavItems}>
        Bliv Medlem
      </Link>
    </nav>
  );
}

const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;

  #mainNavContainer {
    position: ${theme.headerPosition};
    top: ${theme.headerContainerHeight};
    right: 0;
    height: calc(100vh - ${theme.headerContainerHeight});
    left: 0;
    background: ${theme.navBackgroundColorMobile};
    transition: transform ${theme.transitionShort};

    &[aria-hidden="false"] {
      transform: translate(0, 0);
    }
  }
  
  #navMemberSignup {
    display: none;
  }

  #toggleNav {
    order: 1;
  }

  a, button {
    font-size: .9rem;
  }
  
  #mainNav {
    display: flex;
    flex-direction: column;
    margin: 0;
    height: calc(100vh - ${theme.headerContainerHeight});
    overflow-y: scroll;
  }

  #mainNav > li {
    list-style: none;
    text-align: left;
    position: relative;
    z-index: 500;
    transition: transform ${theme.transitionLongAndFunction};

    &.theend {
      flex-grow: 1;
      border-top: 1px solid ${theme.navItemBorderColor};

      &:after {
        content: "";
        display: block;
        background: ${theme.navBackgroundColorMobile};
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 520;
      }
    }

    &[data-active="true"] > a,
    &[data-active="true"] > button,
    > a:hover, 
    > a:focus,
    > button:hover,
    > button:focus {
      background-color: ${theme.navItemBackgroundColorFocus};
      color: ${theme.navItemColorFocus};

      &:after {
        /* filter: brightness(3); */
      }
    }

    > a, > button {
      width: 100%;
      height: 100%;
      display: block;
      appearance: none;
      box-sizing: border-box;
      padding: 15px ${theme.containerPadding};
      position: relative;
      border: 0;
      border-top: 1px solid ${theme.navItemBorderColor};
      background: ${theme.navItemBackgroundColor} linear-gradient(${theme.navItemRightArrowBackgroundColor} 0%, ${theme.navItemRightArrowBackgroundColor} 100%) right center no-repeat;
      background-size: 60px 100%;
      font-family: inherit;
      font-weight: 600;
      font-size: 1rem;
      text-align: left;
      color: ${theme.navItemColor};
      cursor: pointer;
      text-decoration: none;
      -webkit-font-smoothing: auto;
      outline: 0;
      z-index: 550;
      overflow: hidden;
      box-shadow: 0 4px 4px 0 rgba(0,0,0,.05);

      &[aria-expanded="true"]:after {
        transform: translateY(-50%) rotate(90deg);
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 60px;
        /* background: ${theme.navItemRightArrowBackgroundColor}; */
        transition: transform ${theme.transitionShortAndFunction};
      }

      &:after {
        content: "";
        display: block;
        height: 20px;
        width: 20px;
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0yNC4yOTEsMTQuMjc2TDE0LjcwNSw0LjY5Yy0wLjg3OC0wLjg3OC0yLjMxNy0wLjg3OC0zLjE5NSwwbC0wLjgsMC44Yy0wLjg3OCwwLjg3Ny0wLjg3OCwyLjMxNiwwLDMuMTk0ICBMMTguMDI0LDE2bC03LjMxNSw3LjMxNWMtMC44NzgsMC44NzgtMC44NzgsMi4zMTcsMCwzLjE5NGwwLjgsMC44YzAuODc4LDAuODc5LDIuMzE3LDAuODc5LDMuMTk1LDBsOS41ODYtOS41ODcgIGMwLjQ3Mi0wLjQ3MSwwLjY4Mi0xLjEwMywwLjY0Ny0xLjcyM0MyNC45NzMsMTUuMzgsMjQuNzYzLDE0Ljc0OCwyNC4yOTEsMTQuMjc2eiIgZmlsbD0iIzUxNTE1MSIvPjwvc3ZnPg==) 0 0 no-repeat;
        background-size: 100%;
        text-decoration: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(0deg);
        transition: transform ${theme.transitionShortAndFunction};
        right: ${theme.containerPadding};
      }
    }
  }

  #mainNav > li > ul {
    margin: 0;
    padding: ${theme.containerPadding} ${useSize(theme.containerPadding) * 2}px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 540;
    background: ${theme.navItemBackgroundColor};
    opacity: 0;
    transition: ${theme.transitionShortAndFunction};

    a {
      display: block;

      span {
        display: none;
      }

      strong {
        font-weight: 500;
      }

      &:hover {
        opacity: 1;

        strong {
          color: ${color.black};
        }
      }
    }

    &[aria-hidden="false"] {
      opacity: 1;
      transition-delay: .1s;
    }
  }
  
  /*
   * DESKTOP
   */
  @media ${media.displayNav} {
    #navMemberSignup {
      display: block;
      min-width: 110px;
    }

    #toggleNav {
      display: none;
      order: auto;
    }

    #mainNavContainer {
      position: static;
      transform: translateX(0);
      height: auto;
    }

    #mainNav {
      flex-direction: row;
      overflow-y: visible;
      margin-right: ${theme.containerPadding};
      background: ${theme.navBackgroundColorDesktop};
      height: ${theme.headerHeight};
    }
    
    #mainNav > li {
      overflow: visible;
      transition: none;

      &[data-active="true"] + li {
        z-index: auto;
      }

      > a, > button {
        background: transparent;
        border: 0;
        z-index: auto;
        font-weight: 500;
        font-size: .9rem;
        box-shadow: none;
        line-height: ${theme.headerHeight};
        padding: 0 15px;

        &:before {
          display: none;
        }

        &:after {
          /* Small triangle on top of submenu */
          content: "";
          display: block;
          height: ${theme.navItemDropdownArrowSize};
          width: ${theme.navItemDropdownArrowSize};
          border: 1px solid ${theme.navItemBorderColor};
          border-width: 1px 0 0 1px;
          border-radius: 1px 1px 0 1px;
          background: ${theme.navItemDropdownBackgroundColor};
          pointer-events: none;
          position: absolute;
          top: -2000px;
          left: 50%;
          opacity: 0;
          transform: translate(-50%, ${0 - (useSize(theme.navItemDropdownArrowSize) * 2.5) - useSize(theme.navItemDropdownAnimateDistance)}px) rotate(45deg);
          transition: ${theme.transitionShort} ${theme.transitionFunction};
          transition-property: opacity, transform;
          filter: initial;
          z-index: 700;
        }
      }

      > button[aria-expanded="true"] {
        &:after {
          /* Small triangle on top of submenu */
          content: "";
          filter: initial;
          top: 100%;
          opacity: 1;
          transform: translate(-50%, ${0 - useSize(theme.navItemDropdownArrowSize) * 2.5}px) rotate(45deg);
        }
      }

      &.theend {
        display: none;
      }
    }

    #mainNav > li > ul {
      display: block;
      position: absolute;
      background: ${theme.navItemDropdownBackgroundColor};
      padding: 1.5rem 0;
      border-radius: 5px;
      text-align: left;
      left: 20px;
      top: -2000px;
      right: auto;
      opacity: 0;
      transform: translate(-50%, ${0 - useSize(theme.navItemPadding) - useSize(theme.navItemDropdownAnimateDistance)}px);
      transition: ${theme.transitionShortAndFunction};
      transition-property: opacity, transform;
      transition-delay: 0s !important;
      /* box-shadow: 0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), 0 -18px 60px -10px rgba(0,0,0,.025); */
      box-shadow: 0 10px 50px 0 rgba(0,0,0,.2);
      border: 1px solid ${theme.navItemBorderColor};
      z-index: 600;

      &:after {
        content: "";
        display: block;
        left: -50px;
        right: -50px;
        bottom: -50px;
        top: 0;
        position: absolute; 
      }

      &[aria-hidden="false"] {
        top: 100%;
        transform: translate(-50%, -${theme.navItemPadding});
        opacity: 1;
      }
    }

    #mainNav > li > ul > li {
      list-style: none;
      position: relative;
      z-index: 510;
      margin: 0;

      a {
        padding: 0.5em 2.5rem;
        box-sizing: border-box;
        text-decoration: none;
        opacity: .9;
        width: 100%;
        display: block;
        outline: 0;

        span, strong {
          white-space: nowrap;
          display: block;
          font-size: .8rem;
        }

        strong {
          font-weight: 600;
        }

        span {
          font-weight: 400;
          color: ${Color(color.brown).alpha(0.8)};
        }

        &:hover,
        &:focus {
          opacity: 1;

          strong {
            color: ${color.black};
          }

          span {
            color: ${color.brown};
          }
        }

        &:focus, &:active {
          background: ${Color(color.brown).alpha(0.1)};
        }
      }
    }
  }
`;

Navbar.propTypes = {
  data: PropTypes.shape({
    allFile: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          nodes: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              childMdx: PropTypes.shape({
                frontmatter: PropTypes.shape({
                  title: PropTypes.string,
                  nav: PropTypes.shape({
                    display: PropTypes.bool,
                    title: PropTypes.string,
                    desc: PropTypes.string,
                  }),
                }),
                fields: PropTypes.shape({
                  slug: PropTypes.string,
                }),
              }),
              relativeDirectory: PropTypes.string,
            }),
          ),
          fieldValue: PropTypes.string,
        }),
      ),
    }),
  }).isRequired,
};

export const Nav = () => (
  <StaticQuery
    query={navItemQuery}
    render={(data, count) => <Navbar data={data} count={count} />}
  />
);
