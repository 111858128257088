import bp from './breakpoints.module.scss';
import { useSize } from '../utils/useSize';

const points = {};

Object.keys(bp).forEach((k) => {
  points[k] = useSize(bp[k]);
});

// Removes px from all breakpoints
export const breakpoints = points;
