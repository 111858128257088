import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const ImageWrapper = ({ children, aspectRatio, position }) => (
  <Wrapper className="gatsby-image-wrapper" position={position} style={{ paddingBottom: aspectRatio ? `${100 / aspectRatio}%` : 0 }}>
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  box-sizing: content-box;

  .aspectRatio {
    width: 100%;
  }

  img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: ${({ position }) => position};
    opacity: 1;
    transition: opacity 500ms ease 0s;
  }
`;

ImageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  aspectRatio: PropTypes.number,
  position: PropTypes.string,
};

ImageWrapper.defaultProps = {
  aspectRatio: 1,
  position: 'center center',
};
