import React, { useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Recaptcha from 'react-google-recaptcha';
import styled from 'styled-components';
import Markdown from 'markdown-to-jsx';
import { useForm } from '@formcarry/react';
import { FormSuccess } from './FormSuccess';
import { vr } from '../utils';
import { theme, media, color } from '../theme';
import { SplitView } from './editorComponents/SplitView';

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;

const formItems = [
  {
    id: 'signup-name',
    type: 'text',
    label: 'Navn',
    name: 'Navn',
    placeholder: 'Fulde navn',
    size: 'full',
    pattern: /^[a-zA-ZæøåÆØÅ\s]+$/,
  },
  {
    id: 'signup-address',
    type: 'text',
    label: 'Adresse',
    name: 'Adresse',
    placeholder: 'Vej og husnummer',
    size: 'full',
    pattern: /^[a-zA-ZæøåÆØÅ0-9\s]+$/,
  },
  {
    id: 'signup-postcode',
    type: 'number',
    label: 'Postnummer',
    name: 'Postnummer',
    placeholder: 'Postnummer',
    size: 'half',
    pattern: /^[0-9]{4}$/,
  },
  {
    id: 'signup-city',
    type: 'text',
    label: 'By',
    name: 'By',
    placeholder: 'By',
    size: 'half',
    pattern: /^[a-zA-ZæøåÆØÅ\s]+$/,
  },
  {
    id: 'signup-dob',
    type: 'date',
    label: 'Fødselsdato',
    name: 'Fødselsdato',
    placeholder: 'F.eks. 01/01/1980',
    size: 'full',
    pattern: /^[\d]{4}-[\d]{2}-[\d]{2}$/,
  },
  {
    id: 'signup-email',
    type: 'email',
    label: 'Email',
    name: 'Mail',
    placeholder: 'Email-adresse',
    size: 'full',
    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
];

const testItems = [
  {
    id: 'signup-name',
    type: 'text',
    label: 'Navn',
    name: 'Navn',
    placeholder: 'Fulde navn',
  },
  {
    id: 'signup-email',
    type: 'text',
    label: 'Email',
    name: 'Email',
    placeholder: 'Email',
  },
];

export const SignupForm = ({ isPreviewMode }) => {
  // Call the `useForm` hook in your function component
  const { state, submit } = useForm({
    id: 'NTe1_IbpXV_z',
  });

  const recaptchaRef = useRef(null);

  // Object of default values of true to when trying to submit
  const defaults = formItems.reduce((acc, cur) => ({ ...acc, [cur.name]: true }), {});

  const defaultValues = formItems.reduce((acc, cur) => ({ ...acc, [cur.name]: '' }), {});

  // Object of default values of true to when trying to submit
  const [ value, setValue ] = useState({ ...defaultValues });

  const [ isInvalid, setIsInvalid ] = useState({});

  const [ displaySuccessMessage, setDisplaySuccessMessage ] = useState(false);

  function handleChange(name, val, pattern, onBlur = false) {
    const invalid = (val.match(pattern) === null);

    setValue({ ...value, [name]: val });

    if (!invalid || typeof isInvalid[name] !== 'undefined' || (onBlur && val.length > 0)) {
      setIsInvalid({ ...isInvalid, [name]: invalid });
    }
  }

  function handleSubmit(e) {
    const alreadyHandled = isInvalid;

    setIsInvalid({ ...defaults, ...alreadyHandled });

    if (Object.keys(alreadyHandled).length === formItems.length && Object.values(alreadyHandled).every((i) => !i)) {
      // Submit the form
      submit(e);
    } else {
      e.preventDefault();
    }
  }

  useEffect(() => {
    if (state.submitted) {
      // Reset the input values
      setValue(defaultValues);

      // Show message
      setDisplaySuccessMessage(true);

      // Set state of all input to undefined
      setIsInvalid({});

      // Reset captcha
      recaptchaRef.current.reset();

      // Remove message after 5s
      setTimeout(() => {
        setDisplaySuccessMessage(false);
      }, 5000);
    }
  }, [state.submitted]);

  return (
    <form
      data-margin="medium"
      className="vertical"
      onSubmit={handleSubmit}
    >
      <h2>Bliv medlem</h2>
      { formItems.map(({ id, name, placeholder, size, label, pattern, type }) => (
        <label htmlFor={id} data-size={size} key={id} aria-invalid={isInvalid[name]}>
          <span>{label}</span>
          <input
            type={type}
            name={name}
            placeholder={placeholder}
            id={id}
            aria-invalid={isInvalid[name]}
            onChange={(e) => handleChange(name, e.target.value, pattern, false)}
            onBlur={(e) => handleChange(name, e.target.value, pattern, true)}
            value={value[name]}
          />
        </label>
      ))}
      { displaySuccessMessage && (
        <FormSuccess style={{ marginBottom: vr(1) }}>Din ansøgning blev sendt.</FormSuccess>
      )}
      { !isPreviewMode && (
        <Row>
          <Recaptcha
            sitekey={RECAPTCHA_KEY}
            className="recaptcha"
            ref={recaptchaRef}
          />
          <button className="btn green-fill" type="submit">Ansøg om medlemskab</button>
        </Row>
      )}
    </form>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .btn {
    margin: 0;
  }
`;
