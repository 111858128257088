import React from 'react';
import styled from 'styled-components';
import { theme, media, color } from '../theme';

export function Quote({ children }) {
  return (
    <P data-margin="medium auto" data-font="display">
      {children}
    </P>
  );
}

const P = styled.p`
  width: 100%;
  max-width: 600px;
  padding: 0 40px;
  text-align: center;
  display: block;
  font-size: 1.2rem;
`;
