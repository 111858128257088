/*
 * Vertical rhytm helper function
*/

import { theme } from '../theme/theme';

export function vr(value) {

  return `${theme.verticalBaseline * value}rem`;

}
