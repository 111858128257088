import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Image } from './Image';
import { Card } from './Card';
import { media } from '../theme';
import { vr } from '../utils';

const newsQuery = graphql`
  query NewsPosts{
    allMdx(
      sort: {
        fields: [frontmatter___date],
        order: DESC
      },
      filter: {
        frontmatter: {
          templateKey: {eq: "news-post"},
          category: {eq: "seneste"},
          featuredPost: {eq: true}
        }
      },
      limit: 1
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "Do MMMM YYYY", locale: "da")
            title
            featuredPost
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 80 ) {
                  ...GatsbyImageSharpFluid
                  sizes
                }
              }
            }
          }
        }
      }
    }
  }
`;

function NewsPosts({ height, data }) {
  const { edges: posts } = data.allMdx;

  return (
    <Section>
      {posts && posts.map(({ node: post }) => (
        <div className={`container height-${height}`} key={post.id}>
          <div className="content">
            <h2 className="small-pre-heading">Seneste tur</h2>

            <article className={`post ${post.frontmatter.featuredPost && 'is-featured'}`}>

              <div className="post-meta">
                <h3><Link to={post.fields.slug}>{post.frontmatter.title}</Link></h3>

                <p>{post.excerpt}</p>

                <Link className="with-arrow" to={post.fields.slug} preventScrollJump>Læs mere</Link>
              </div>

              <div className="post-thumbnail">
                <Image src={post.frontmatter.image} />
              </div>

            </article>
          </div>
        </div>
      ))
      }
    </Section>
  );
}

const Section = styled.section`
  div.content {
    @media ${media.tabletM} {
      width: auto;
    }
  }

  .container {
    flex-direction: column;
    justify-content: center;
  }
  
  .post {
    display: flex;
    flex-direction: column;
    
    @media ${media.tabletM} {
      flex-direction: row;
      justify-content: space-between;

      .post-meta {
        width: 60%;
      }
    }

    .post-thumbnail {
      width: 100%;
      order: -1;
      margin-bottom: 1rem;

      @media ${media.tabletM} {
        width: 35%;
        order: 0;
        margin-bottom: 0;
      }
    }
  }
`;

NewsPosts.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
  height: PropTypes.string,
};

NewsPosts.defaultProps = {
  height: 'fixed',
};

export const NewsRoll = ({ height }) => (
  <StaticQuery
    query={newsQuery}
    render={(data, count) => <NewsPosts height={height} data={data} count={count} />}
  />
);
