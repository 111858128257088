import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { blankImage } from '../utils';
import { ImageWrapper } from './ImageWrapper';

export const Image = ({ src: image, alt, style, innerStyle, position, loading, fadeIn }) => {
  if (image?.childImageSharp?.fluid) {
    return (
      <Img
        fluid={image.childImageSharp.fluid}
        alt={alt}
        style={style}
        loading={loading}
        fadeIn={fadeIn}
        imgStyle={{ objectPosition: position }}
      />
    );
  }
  if (image?.childImageSharp?.resize) {
    return (
      <ImageWrapper style={style} aspectRatio={image.childImageSharp.resize.aspectRatio} position={position}>
        <img
          src={image.childImageSharp.resize.src}
          alt={alt}
          style={innerStyle}
        />
      </ImageWrapper>
    );
  }
  if (image?.childImageSharp?.fixed) {
    return (
      <Img
        fixed={image.childImageSharp.fixed}
        alt={alt}
        style={style}
        loading={loading}
        fadeIn={fadeIn}
        imgStyle={{ objectPosition: position }}
      />
    );
  }
  if (image?.url) {
    return <img src={image.url} alt={alt} style={style} />;
  }
  if (image?.publicURL) {
    return <img src={image.publicURL} alt={alt} style={style} />;
  }
  if (typeof image === 'string') {
    return <img src={image} alt={alt} style={style} />;
  }
  return <img src={blankImage} alt="Billedet blev ikke indlæst" />;
};

Image.propTypes = {
  src: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
  alt: PropTypes.string,
  style: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
  innerStyle: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
  position: PropTypes.string,
  loading: PropTypes.string,
  fadeIn: PropTypes.bool,
};

Image.defaultProps = {
  src: {},
  alt: '',
  style: {},
  innerStyle: {},
  position: 'center center',
  loading: 'lazy',
  fadeIn: true,
};
