import React, { useEffect } from 'react';
import CC, { Cookies } from 'react-cookie-consent';
import { theme, color } from '../theme';
import { vr, useDisableScroll } from '../utils';

export const CookieConsent = () => (
  <CC
    location="none"
    buttonText="Accepter alle"
    declineButtonText="Kun nødvendige"
    buttonClasses="styled medium"
    cookieName="gatsby-gdpr-google-analytics"
    enableDeclineButton
    declineButtonClasses="styled gray medium"
    style={{
      display: 'block',
      position: 'fixed',
      top: '50%',
      left: '50%',
      bottom: 'auto',
      right: 'auto',
      width: '80vw',
      transform: 'translate(-50%,-50%)',
      maxWidth: 650,
      background: color.cream,
      color: color.black,
      boxShadow: '0 0 0 100vw rgba(0,0,0,.5)',
      padding: vr(1),
      textAlign: 'center',
    }}
    disableButtonStyles
    buttonStyle={{ margin: vr(0.5) }}
  >
    <h1 style={{ textAlign: 'center', display: 'block' }}>Vi bruger cookies</h1>
    <p>
      Vi bruger cookies til at give dig den bedste oplevelse.<br />
      Når du klikker ‘Accepter alle’, giver du accept til brug af cookies.
    </p>
  </CC>
);
