import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Color from 'color';
import PropTypes, { array } from 'prop-types';
import { Image } from './Image';
import { theme, media, color } from '../theme';
import { vr } from '../utils';

const container = {
  width: '100%',
  boxSizing: 'border-box',
  maxWidth: 650,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const content = {
  width: '60%',
};

const h2 = {
  marginTop: 0,
};

const imgWrapper = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const img = {
  width: 100,
  height: 100,
  borderRadius: 1000,
  marginRight: vr(1),
  background: color.brown,
};

const strong = {
  display: 'inline-block',
  marginRight: '1ch',

};

const small = {

};

const span = {
  display: 'block',
};

export const Person = ({ name, title, description, image }) => (
  <SC>
    <div data-margin="small" className="component" style={container}>
      <div className="image" style={imgWrapper}>
        <Image src={image} style={img} />
      </div>
      <div className="content" style={content}>

        <strong style={strong}>{name}</strong>
        <small style={small}>{title}</small>
        <span style={span}>{description}</span>

      </div>
    </div>
  </SC>
);

Person.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
};

Person.defaultProps = {
  name: '',
  title: '',
  description: '',
  image: null,
};

const SC = styled.div`
  
`;
