import React from 'react';
import { useStaticQuery, Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { ContactForm } from './ContactForm';
import { Content } from './Content';
import { theme, media, color } from '../theme';
import { useConfig, vr } from '../utils';
import Logo from '../img/logo.inline.svg';

export function Footer() {
  const { title } = useConfig();

  const { mdx: content } = useStaticQuery(
    graphql`
      query {
        mdx(fields: {slug: {eq: "/footer/"}}) {
          body
        }
      }
    `,
  );

  return (
    <FooterComponent>
      <div className="container">
        <div>
          <Content>{content.body}</Content>
          <span className="copy">&copy; Fodboldloge.dk</span>
          <span className="terms"><Link to="/terms" title="Betingelser">Betingelser</Link></span>
        </div>
        <Logo />
      </div>
    </FooterComponent>
  );
}

const FooterComponent = styled.footer`
  background: linear-gradient(to bottom, ${theme.footerBackgroundColorTop}, ${theme.footerBackgroundColorBottom});
  color: #fff;
  overflow: hidden;
  position: relative;
  z-index: 60;

  .container {
    display: flex;
    padding-top: 50px;
    padding-bottom: 0;
    min-height: auto;

    ul li {
      list-style: none;
      font-family: "Barlow Condensed", sans-serif;
      font-size: 1.4rem;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }

    span {
      display: block;
      font-size: 0.6em;
      margin-top: 1rem;
      opacity: 0.8;
      text-transform: none;
    }

    svg {
      width: 40vw;
      order: 5;
      position: relative;
      margin-top: -20vw;
      top: 20vw;
      left: 10vw;
      align-self: flex-end;
      color: ${color.white};
      opacity: 0.5;
    }
  }

  a {
    color: inherit;
  }
  a:hover {
    color: ${color.yellow};
  }
`;
