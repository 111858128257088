import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Color from 'color';
import { theme, media, color } from '../theme';
import { Table } from './editorComponents';
import { useSize, vr } from '../utils';

export function TableOfContents({ content }) {
  return (
    <Container id="tableOfContents" ariaLabel="Indholdsfortegnelse">
      <ol>
        { content.length > 2
          && content.map((item) => (
            <li><a href={item.url} title={`Gå til ${item.title}`}>{item.title}</a></li>
          ))
        }
      </ol>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin: ${vr(0.5)} 0 ${vr(1)};
  box-sizing: border-box;
  font-size: .8em;
  /* padding: ${vr(1)} ${vr(0.25)};
  background: ${color.white};
  box-shadow: ${theme.containerShadow}; */
  /* border: 1px solid ${Color(color.gold).alpha(0.2)}; */

  h3 {
    margin: 0 calc(${theme.listMarginHorizontal} + ${theme.listItemCounterOffset}) ${vr(0.5)};
    display: none;
  }
  
  ol {
    /* margin: 0; */
    margin-top: 0;
    margin-bottom: 0;

    li {
      padding-right: 0;
      opacity: .8;

      &:hover {
        opacity: 1;
      }

      &:before {
        font-weight: 600;
      }
    }
  }

  a {
    color: ${color.brown};
    text-decoration: none;
    font-weight: 400;

    &:hover {
      text-decoration: underline;
    }
  }

  @media ${media.tabletL} {
    margin: 0 0 ${vr(1)} ${vr(1.5)};
    float: right;
    max-width: 350px !important;
  }
`;

TableOfContents.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

TableOfContents.defaultProps = {
  content: [],
};
