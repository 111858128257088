import React, { useState } from 'react';
import { useForm } from '@formcarry/react';
import { FormSuccess } from './FormSuccess';

const regex = {
  name: /^[a-zA-Z\s]+$/,
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const ContactForm = () => {
  // Call the `useForm` hook in your function component
  const { state, submit } = useForm({
    id: 'vd2_-dFsPqt0',
  });

  const [ message, setMessage ] = useState('Antal personer: \nRejse periode: \nSpecielle ønsker: ');

  const [ itemStatus, setItemStatus ] = useState({});

  function handleChange({ target }) {
    const { value, name } = target;

    if (name === 'message') setMessage(value);

    const invalid = (value.match(regex[name]) === null);

    if (!invalid || typeof itemStatus[name] !== 'undefined') {
      setItemStatus({ ...itemStatus, [name]: invalid });
    }
  }

  function handleBlur({ target }) {
    const { value, name } = target;

    const isInvalid = (value.match(regex[name]) === null);

    if (value.length > 0) setItemStatus({ ...itemStatus, [name]: isInvalid });
  }

  // Success message
  if (state.submitted) {
    return <FormSuccess>Din besked blev sendt.</FormSuccess>;
  }

  return (
    <form
      name="contact-recaptcha"
      method="post"
      onSubmit={submit}
    >
      <noscript>
        <p>This form won’t work with Javascript disabled</p>
      </noscript>

      <div className="row">
        <div className="field">
          <label aria-label="Navn" htmlFor="footer-name" aria-invalid={itemStatus.name}>
            <input
              type="text"
              id="footer-name"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Navn"
              required
              aria-invalid={itemStatus.name}
            />
          </label>
        </div>

        <div className="field">
          <label aria-label="Email" htmlFor="footer-email" aria-invalid={itemStatus.email}>
            <input
              type="email"
              id="footer-email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Email"
              required
              aria-invalid={itemStatus.email}
            />
          </label>
        </div>
      </div>

      <div className="field">
        <label aria-label="Besked" htmlFor="your-message">
          <textarea id="your-message" name="message" onChange={handleChange} value={message} />
        </label>
      </div>

      <button className="styled center" type="submit">Send besked</button>
    </form>
  );
};
